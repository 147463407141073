<template>
  <v-navigation-drawer
    ref="drawer"
    id="app-drawer"
    :value="drawer"
    app
    mobile-breakpoint="768"
    color="#215450"
    floating
    width="240"
    style="height: calc(100vh - 64px); margin-top: 64px"
  >
    <div class="sidebar-content-wrapper">
      <v-list-item three-line dark>
        <v-avatar size="34">
          <v-img src="../../assets/images/user.svg" />
        </v-avatar>
        <v-list-item-title style="margin-left: 15px">
          {{ identity }}
          <div
            style="font-size: 14px; text-transform: capitalize; color: #15faa8"
            v-if="role"
          >
            {{ role.replace('_', ' ').toLowerCase() }}
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-divider class="mx-2 mb-1" />
      <ul class="sidebar-nav">
        <a
          :href="`/${item.path}`"
          v-for="item in generateRoutes"
          :key="item.name"
          class="mb-1"
          :class="[
            $route.name === item.path ? 'selected-item' : 'sidebar-main-link',
          ]"
        >
          <v-icon dark>mdi-{{ item.icon }}</v-icon>
          <v-list-item-title class="items-title">{{
            item.name
          }}</v-list-item-title>
        </a>
      </ul>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
import { TokenService } from "@/services/storage.service";

export default {
  name: "Sidebar",
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      identity: "",
      routes: [
        {
          name: "Invoice",
          path: "invoice",
          icon: "air-filter",
          permissions: ["GENERATE_INVOICE"],
        },
        {
          name: "Balance confirmation",
          path: "balance-confirmation",
          icon: "cash-multiple",
          permissions: ["GENERATE_BALANCE_CONFIRMATION"],
        },
        {
          name: "Balances",
          path: "balances",
          icon: "wallet-outline",
          permissions: ["FETCH_CRYPTO_WALLET_BALANCE", "FETCH_BANK_BALANCE", "FETCH_CLIENT_BALANCES"],
        },
        {
          name: "Create organisation",
          path: "create-organisation",
          icon: "account-multiple-plus-outline",
          permissions: ["MANAGE_ORGANISATIONS"],
        },
        {
          name: "Update organisation",
          path: "update-organisation",
          icon: "account-multiple-check-outline",
          permissions: ["MANAGE_ORGANISATIONS"],
        },
        {
          name: "Country blacklist",
          path: "country-blacklist",
          icon: "block-helper",
          permissions: ["MANAGE_BLACKLISTED_COUNTRIES"],
        },
        {
          name: "Banking",
          path: "banking",
          icon: "bank",
          permissions: ["FETCH_BANK_ACCOUNTS"],
        },
        {
          name: "Illicit deposits",
          path: "illicit-deposits",
          icon: "disc-alert",
          permissions: ["FETCH_BLOCKED_CRYPTO_DEPOSITS"],
        },
        {
          name: "Travel rule deposits",
          path: "travel-rule-deposits",
          icon: "file-document-alert",
          permissions: ["GET_DEPOSITS_WITHOUT_TRAVEL_RULE"],
        },
        {
          name: "Process explorer",
          path: "process-explorer/wallet",
          icon: "view-dashboard-outline",
          permissions: ["VIEW_PROCESSES"],
        },
        {
          name: "Transaction ledger",
          path: "transaction-ledger",
          icon: "book-open-outline",
          permissions: ["GENERATE_TRANSACTION_LEDGER"],
        },
        {
          name: "Unallocated deposits",
          path: "unallocated-deposits",
          icon: "cash-fast",
          permissions: ["CREATE_UNASSOCIATED_DEPOSIT"],
        },
        {
          name: "Compliance rules",
          path: "compliance-rules",
          icon: "format-list-bulleted-type",
          permissions: ["FETCH_COMPLIANCE_RULES"],
        },
        {
          name: "Case management",
          path: "case-management",
          icon: "notebook-outline",
          permissions: ["FETCH_COMPLIANCE_CASES"],
        },
        {
          name: "Whitelabel fiat deposits",
          path: "whitelabel-fiat-deposit",
          icon: "currency-usd",
          permissions: ["CREATE_MANUAL_MOVEMENT"],
        },
        {
          name: "FIU Report",
          path: "fiu-report",
          icon: "file-chart",
          permissions: ["FETCH_COMPLIANCE_REPORTS"],
        },
        {
          name: "Manual movements",
          path: "manual-movements",
          icon: "cash-fast",
          permissions: ["CREATE_MANUAL_MOVEMENT"],
        },
        {
          name: "Two-factor auth",
          path: "authentication",
          icon: "fingerprint",
          permissions: [],
        },
        {
          name: "Change password",
          path: "change-password",
          icon: "key-variant",
          permissions: [],
        },
        {
          name: "Manage operators",
          path: "users",
          icon: "account-group",
          permissions: ["MANAGE_OPERATORS"],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.user.role,
      email: (state) => state.user.email,
      twoFactor: (state) => state.user.twoFactor,
    }),
    generateRoutes() {
      let routes = [...this.routes];
      if (!this.twoFactor) {
        routes = routes.filter((link) => link.name === "Two-factor auth");
      }
      return routes.filter((link) => link.permissions.every(p => TokenService.getConfig("permissions").includes(p)));
    },
  },
  mounted() {
    this.identity = TokenService.getConfig("email");
  },
};
</script>
<style lang="scss" scoped>
a {
  all: unset;
}

.sidebar-main-link {
  padding: 7px 25px 7px 30px;
  color: #fff;
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  font-weight: 500;

  &:hover {
    background: rgba(92, 92, 94, 0.452);
    width: 93%;
    cursor: pointer;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
  }
}

.selected-item {
  @extend .sidebar-main-link;
  background: rgba(92, 92, 94, 0.6);
  width: 93%;
  cursor: pointer;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
}

.sidebar-main-link-app {
  padding: 0 0 0 25px;
  color: #fff !important;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 100%;
  font-weight: 500;

  div.pen {
    width: 30px;
    height: auto;
    visibility: hidden;
    border-radius: 50%;
    padding: 5px;
    background: rgba(92, 92, 94, 0.9);
    background: rgba(30, 148, 226, 0.9);
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    pointer-events: auto !important;
    margin: 0 10px 0 10px;

    & > i.v-icon::before {
      pointer-events: none !important;
    }
  }

  &:hover {
    background: rgba(92, 92, 94, 0.452);
    cursor: pointer;

    div.pen {
      transition: "visibility" 0s linear !important;
      pointer-events: none;
      visibility: visible;
    }
  }
}

.selected {
  @extend .sidebar-main-link-app;
  background: rgba(92, 92, 94, 0.452);
  cursor: pointer;

  div.pen {
    visibility: visible !important;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px;
}

.items-title {
  color: #ffffff;
  font-size: 14px;
  margin-left: 10px;
}
</style>
