export function generateRolePermissions(allUserPermissions, roles, includeAll = false) {
    roles.forEach((role) => {
        role.humanReadablePermissions = []
        allUserPermissions.forEach((perm) => {
            const isFull = perm.full.length && perm.full.every((p) => role.permissions.includes(p))
            const isViewOnly = perm.view.length ? perm.view.every((perm) => role.permissions.includes(perm)) : false

            const access = isFull ? "Full access" : isViewOnly ? "View access" : "No access"

            if (includeAll) {
                role.humanReadablePermissions.push({name: perm.name, access: access})
            } else {
                if (access !== "No access") role.humanReadablePermissions.push({name: perm.name, access: access})
            }
        })
    })
    return roles
}

export function determineDefaultRoute(permissions) {
    if (permissions.includes("GENERATE_INVOICE")) return "/invoice"
    if (permissions.includes("GENERATE_BALANCE_CONFIRMATION")) return "/balance-confirmation"
    if (permissions.includes("MANAGE_BLACKLISTED_COUNTRIES")) return "/country-blacklist"
    if (permissions.includes("VIEW_PROCESSES")) return "/process-explorer/wallet"
    if (["FETCH_CRYPTO_WALLET_BALANCE", "FETCH_BANK_BALANCE", "FETCH_CLIENT_BALANCES"].every(p => permissions.includes(p))) return "/balances"

    return "/authentication"
}
